<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dae_reports.approved_beneficiary_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="6" xl="4">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.fiscal_year')}}
                  </template>
                  <v-select name="organization"
                    v-model="search.fiscal_year_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= fiscalYearList
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="season_id"
                      >
                      <template v-slot:label>
                          {{$t('dae_config.season_name')}}
                      </template>
                      <v-select name="season_id"
                        v-model="search.season_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= seasonSetupList
                        :placeholder="$t('globalTrans.select')"
                      />
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_id"
                      >
                      <template v-slot:label>
                          {{$t('dae_config.crop_name')}}
                      </template>
                      <v-select name="crop_id"
                        v-model="search.crop_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= cropList
                        :placeholder="$t('globalTrans.select')"
                      />
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="subsidy_type_id"
                    >
                    <template v-slot:label>
                    {{ $t('bsri_config.subsidy_type') }}
                    </template>
                    <v-select name="subsidy_type_id"
                      v-model="search.subsidy_type_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= subsidyTypeList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="subsidy_id"
                    >
                    <template v-slot:label>
                      {{ $t('bsri_config.subsidy_name') }}
                    </template>
                    <v-select name="subsidy_id"
                      v-model="search.subsidy_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= subsidyList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="district_id"
                  >
                  <template v-slot:label>
                    {{ $t('org_pro_district.district') }}
                  </template>
                  <v-select name="district_id"
                    v-model="search.district_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= districtList
                    :placeholder="$t('globalTrans.select')"
                  />
              </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazilla_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla') }}
                    </template>
                    <v-select name="district_id"
                      v-model="search.upazilla_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= upazilaList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="union_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_union.union') }}
                    </template>
                    <v-select name="district_id"
                      v-model="search.union_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= unionList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('dae_reports.approved_beneficiary_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary" @click="pdfExport" class="mr-2">
                            {{  $t('globalTrans.print') }}
                          </b-button>
                          <export-excel
                            class="btn btn-success mr-2"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :default-value="headerExcelDefault"
                            name="approved-beneficiary-report.xls">
                            {{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('dae_reports.approved_beneficiary_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                <b-row>
                                  <b-col md="4"><strong>{{ $t('elearning_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="4"><strong>{{ $t('dae_config.season_name') }}: </strong>{{ search.season_id  ?  ($i18n.locale === 'en' ? searchResult.season_name : searchResult.season_name_bn) : $t('globalTrans.all') }}</b-col>
                                </b-row>
                                <b-row class="my-4">
                                  <b-col md="4"><strong>{{ $t('bsri_config.subsidy_type') }}: </strong>{{ search.subsidy_type_id ? ($i18n.locale === 'en' ? searchResult.subsidy_type_name : searchResult.subsidy_type_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="4"><strong>{{ $t('bsri_config.subsidy_name') }}: </strong>{{ search.subsidy_id ? ($i18n.locale === 'en' ? searchResult.subsidy_name : searchResult.subsidy_name_bn) : $t('globalTrans.all')  }}</b-col>
                                </b-row>
                            </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_father_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_nid') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.land_quantity') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.crop_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.district') }}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.upazila') }}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.union') }}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.village') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.father_name_bn : info.father_name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.mobile_no,{ useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ $n(info.nid_no,{ useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ $n(info.total_land) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.crop_name_bn : info.crop_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.district_name_bn : info.district_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.upazilla_name_bn : info.upazilla_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.union_name_bn : info.union_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.village_name_bn : info.village_name }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { approveBeneficiaryReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
    },
    data () {
        return {
          search: {
            type: 'Upazila',
            season_id: 0,
            subsidy_type_id: 0,
            subsidy_id: 0,
            fiscal_year_id: 0
          },
          searchResult: {
            fiscal_year: '',
            fiscal_year_bn: '',
            season_name: '',
            season_name_bn: '',
            subsidy_type_name: '',
            subsidy_type_name_bn: '',
            subsidy_name: '',
            subsidy_name_bn: ''
          },
          formData: {
            from_date: '',
            to_date: '',
            status: ''
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          subsidyList: [],
          upazilaList: [],
          unionList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      subsidyTypeList: function () {
        const dataList = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
        return dataList
      },
      seasonSetupList: function () {
        return this.$store.state.incentiveGrant.commonObj.seasonSetupList
      },
      cropList: function () {
        const cropList = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
        return cropList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      },
      districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      },
        headerValue: function () {
          const headerVal = []
          if (this.$i18n.locale === 'en') {
              headerVal[0] = this.headerExcelDefault.orgName
              headerVal[1] = this.headerExcelDefault.address
              headerVal[2] = this.$t('BarcReport.grant_application_status_report')
              headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name
              headerVal[4] = this.$t('bsri_config.subsidy_type') + ':  ' + this.searchResult.subsidy_type_name + '; ' + ' ' + this.$t('bsri_config.subsidy_name') + ': ' + this.searchResult.subsidy_name
            } else {
              headerVal[0] = this.headerExcelDefault.orgNameBn
              headerVal[1] = this.headerExcelDefault.address_bn
              headerVal[2] = this.$t('BarcReport.grant_application_status_report')
              headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year_bn + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name_bn
              headerVal[4] = this.$t('bsri_config.subsidy_type') + ':  ' + this.searchResult.subsidy_type_name_bn + '; ' + ' ' + this.$t('bsri_config.subsidy_name') + ': ' + this.searchResult.subsidy_name_bn
            }
            return headerVal
          },
        excelData: function () {
          const listData = this.datas
          var serial = 0
          const listContractor = listData.map(keyItem => {
            serial++
             if (this.$i18n.locale === 'en') {
                return {
                  'sl ': serial,
                  'Farmer Name': keyItem.name,
                  'Farmer Father Name': keyItem.father_name,
                  'Farmer Mobile Number': keyItem.mobile_no,
                  'Farmer NID': keyItem.nid_no,
                  'Land Quantity': keyItem.total_land,
                  'Crop Name ': keyItem.crop_name,
                  'District ': keyItem.district_name,
                  'Upazila ': keyItem.upazilla_name,
                  'Union ': keyItem.union_name,
                  'Village ': keyItem.village_name
                }
              } else {
                return {
                  'ক্রমিক সংখ্যা': this.$n(serial),
                  'কৃষকের নাম ': keyItem.name_bn,
                  'কৃষকের পিতার নাম': keyItem.father_name_bn,
                  'কৃষকের মোবাইল নম্বর': keyItem.mobile_no,
                  'কৃষকের এনআইডি': keyItem.nid_no,
                  'জমির পরিমাণ': this.$n(keyItem.total_land),
                  'ফসলের নাম ': keyItem.crop_name_bn,
                  'জেলা ': keyItem.district_name_bn,
                  'উপজেলা ': keyItem.upazilla_name_bn,
                  'ইউনিয়ন ': keyItem.union_name_bn,
                  'গ্রাম ': keyItem.village_name_bn
                }
              }
          })
          return listContractor
        }
    },
    watch: {
      'search.subsidy_type_id': function (newValue, OldValue) {
        this.subsidyList = this.getSubsidyList(newValue)
      },
       'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
      },
      'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      }
    },
    methods: {
      getSubsidyList (subsidyTypeId) {
        const subsidyList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
        return subsidyList
      },
      getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

          if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
          }

        return upazilaList
      },
      getUnionList (upazillaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
          if (upazillaId) {
            return unionList.filter(union => union.upazilla_id === upazillaId)
          }
          return unionList
      },
      headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 2)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      pdfExport () {
          const reportTitle = this.$t('dae_reports.approved_beneficiary_report')
          ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.formData)
      },
      async searchData () {
      this.loading = true
      this.showData = true
      const params = Object.assign({}, this.formData)
        await RestApi.getData(incentiveGrantServiceBaseUrl, approveBeneficiaryReport, params).then(response => {
          if (response.success) {
            if (response.data) {
              if (this.search.fiscal_year_id) {
                const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
                this.searchResult.fiscal_year = fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
                this.searchResult.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
              }
              if (this.search.season_id) {
                const seasonObj = this.$store.state.incentiveGrant.commonObj.seasonSetupList.find(seasonItem => seasonItem.value === this.search.fiscal_year_id)
                this.searchResult.season_name = seasonObj !== undefined ? seasonObj.text_en : ''
                this.searchResult.season_name_bn = seasonObj !== undefined ? seasonObj.text_bn : ''
              }
              if (this.search.subsidy_type_id) {
                const seubTypeObj = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.find(fiscalYear => fiscalYear.value === this.search.subsidy_type_id)
                this.searchResult.subsidy_type_name = seubTypeObj !== undefined ? seubTypeObj.text_en : ''
                this.searchResult.subsidy_type_name_bn = seubTypeObj !== undefined ? seubTypeObj.text_bn : ''
              }
              if (this.search.subsidy_id) {
                const subSidyObj = this.$store.state.incentiveGrant.commonObj.subsidyList.find(seasonItem => seasonItem.value === this.search.subsidy_id)
                this.searchResult.subsidy_name = subSidyObj !== undefined ? subSidyObj.text_en : ''
                this.searchResult.subsidy_name_bn = subSidyObj !== undefined ? subSidyObj.text_bn : ''
              }
              this.datas = this.relationData(response.data.data)
            }
          } else {
            this.datas = []
          }
        })
        this.loading = false
      },
      relationData (data) {
        const cropStore = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
        const districtStore = this.$store.state.commonObj.districtList
        const upazillaStore = this.$store.state.commonObj.upazilaList
        const unionStore = this.$store.state.commonObj.unionList

        const listData = data.map(item => {
          const cropObject = cropStore.find(cropItem => cropItem.value === item.crop_id)
          const districtObject = districtStore.find(districtItem => districtItem.value === item.district_id)
          const upazillaObject = upazillaStore.find(upazillaItem => upazillaItem.value === item.upazilla_id)
          const unionObject = unionStore.find(unionItem => unionItem.value === item.union_id)

          const cropData = {
            crop_name: cropObject !== undefined ? cropObject.text_en : ' ',
            crop_name_bn: cropObject !== undefined ? cropObject.text_bn : ' '
          }
          const districtData = {
            district_name: districtObject !== undefined ? districtObject.text_en : ' ',
            district_name_bn: districtObject !== undefined ? districtObject.text_bn : ' '
          }
          const upazillaData = {
            upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : ' ',
            upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ' '
          }
          const unionData = {
            union_name: unionObject !== undefined ? unionObject.text_en : ' ',
            union_name_bn: unionObject !== undefined ? unionObject.text_bn : ' '
          }
          const villageData = {
            village_name: ' ',
            village_name_bn: ' '
          }

          return Object.assign({}, item, cropData, districtData, upazillaData, unionData, villageData)
        })

        return listData
      },
      getStatus (status) {
        if (status === 2) {
            return this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved'
        } else {
          return this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject'
        }
      }
    }
}
</script>
